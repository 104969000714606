<template>
  <div>
    <div class="toolbar py-5 py-lg-15">
      <div class="container-xxl d-flex flex-stack flex-wrap align-items-start">
        <div class="page-title d-flex flex-column me-3">
          <h1 class="text-white">Archive</h1>

          <px-new-breadcrumbs :breadcrumbs="breadcrumbs" />
        </div>
      </div>
    </div>

    <div class="container-xxl pb-10">
      <div class="content flex-row-fluid">
        <pxs-projects :is-archived="true" />
      </div>
    </div>
  </div>
</template>

<script>
import PxNewBreadcrumbs from '@/components/elements/PxNewBreadcrumbs';

import PxsProjects from '@/sections/PxsProjects';

export default {
  components: {
    PxNewBreadcrumbs,
    PxsProjects,
  },
  data() {
    return {
      breadcrumbs: [
        {
          id: '1001',
          title: 'Home',
          to: 'dashboard',
        },
        {
          id: '1002',
          title: 'Projects',
          to: 'projects',
        },
        {
          id: '1003',
          title: 'Archive',
        },
      ],
    };
  },
};
</script>
